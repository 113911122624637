.App1 {
    text-align: center;
  }
  
.App-header1 {
    background-color: #282c34;
    padding: 20px;
    color: white;
  }

button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
  }
  
.home {
    padding: 20px;
  }
  
.create-incident, .demo {
    padding: 20px;
  }
  
.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
.card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  }
  
.incident-card {
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
  }
  
.incident-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
  }
  
.card-title {
    font-size: 1.2em;
    font-weight: bold;
    flex-grow: 1;
    margin-right: 10px;
  }
  
.card-body {
    font-size: 1.0em;
    color: #333;
    /* margin-bottom: 10px; */
  }
  
.card-date {
    font-size: 0.9em;
    color: gray;
  }
  
.card-description {
    font-size: 0.85em;
  }
  
.card-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
.card-architecture {
    font-size: 1em;
    font-weight: bold;
  }
  
.custom-textarea {
    max-width: 500px;
    margin: 0 auto; /* Center the element */
    display: block;
  }
  
.bold-label {
    font-weight: bold;
  }
  
.incident-detail {
    text-align: left;  /* Align text to the left */
    margin: 20px;  /* Add some margin around the container */
    font-family: Arial, sans-serif;  /* Set a clean font */
  }
  
.incident-title {
    margin-bottom: 20px;  /* Add some space below the title */
    font-size: 2em;  /* Increase the font size of the title */
    color: #333;  /* Set a dark color for the title */
  }
  
.incident-detail p, .incident-detail ul {
    margin-bottom: 15px;  /* Add some space below paragraphs and lists */
  }
  
.incident-detail h3, .incident-detail h4 {
    margin-top: 30px;  /* Add space above the headers */
    margin-bottom: 10px;  /* Add space below the headers */
    color: #555;  /* Set a different color for the headers */
  }
  
.incident-detail ul {
    list-style-type: disc;  /* Use disc bullets for the list */
    padding-left: 20px;  /* Indent the list */
  }
  
.incident-detail ul li {
    margin-bottom: 10px;  /* Add space between list items */
  }
  
.incident-detail strong {
    font-weight: bold;  /* Ensure bold text is actually bold */
  }
  
.button-container {
    display: flex;
    justify-content: center;  /* Center the button */
    margin-top: 30px;  /* Add some space above the button */
  }

  .tutorial-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* Add some space below the image */
  }
  
  .tutorial-back-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 1.0); /* Adjust the opacity value to make it dimmer or lighter */
  }

  /*test code*/
  /* src/components/IssuesList.css */


.tutorial-next-button {
  background-color: #5935c9;
  border-color: #5935c9;
  color: white; /* Ensure text color is visible */
  transition: background-color 0.3s, border-color 0.3s;
}

.tutorial-next-button:hover {
  background-color: #432b99; /* Darker shade for hover effect */
  border-color: #432b99;
}

/* Purple background for the Analysis tab */
.nav-tabs .nav-link.bg-purple {
  background-color: #5935c9;
  color: white;
  border-color: #5935c9;
}

.nav-tabs .nav-link.bg-purple:hover, 
.nav-tabs .nav-link.bg-purple:focus {
  background-color: #432b99;
  border-color: #432b99;
}

.nav-tabs .nav-link.bg-purple.active {
  background-color: #432b99;
  border-color: #432b99 #432b99 #fff;
}

/* Ensure text is white and visible */
.nav-tabs .nav-link.bg-purple,
.nav-tabs .nav-link.bg-purple:hover,
.nav-tabs .nav-link.bg-purple:focus,
.nav-tabs .nav-link.bg-purple.active {
  color: white;
}

/* Other styles remain the same */
.modal-90w {
  width: 90%;
  max-width: none !important;
}

.attachment-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transform: translateY(-2px);
}

.attachment-card {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.attachment-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transform: translateY(-2px);
}
  .sidebar {
    width: 250px;
    height: 100%;
    background-color: #f4f5f7;
    transition: all 0.3s;
    overflow-y: auto;
  }
  
  .sidebar.closed {
    width: 60px;
  }
  
  .sidebar .toggle-btn {
    position: absolute;
    right: -15px;
    top: 10px;
    z-index: 1000;
  }
  
  .sidebar-content {
    padding: 20px;
  }
  
  .project-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .project-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .plan-info {
    margin-bottom: 20px;
  }
  
  .upgrade-btn {
    width: 100%;
  }
  
  .upgrade-icon {
    margin-right: 5px;
  }
  
  .back-to-project {
    margin-bottom: 20px;
  }
  
  .sidebar-heading {
    font-size: 0.8rem;
    font-weight: bold;
    color: #5e6c84;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .nav-link {
    color: #42526e;
    padding: 8px 12px;
    border-radius: 3px;
  }
  
  .nav-link:hover, .nav-link.active {
    background-color: #ebecf0;
  }
  
  .view-all-filters {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .company-info {
    margin-top: 20px;
    font-size: 0.8rem;
    color: #6b778c;
  }
  
  .company-info a {
    color: #0052cc;
    text-decoration: none;
  }

.issues-list {
  padding: 20px;
  background-color: #f4f5f7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.issues-list h2 {
  margin-bottom: 20px;
  font-weight: bold;
  color: #0052cc;
}

.issues-table {
  background-color: #ffffff;
}

.issues-table th, .issues-table td {
  vertical-align: middle;
}

.badge {
  font-size: 0.9em;
}

.sidebar {
  background-color: #f8f9fa;
  padding-top: 20px;
  min-height: 100vh;
}

.main-content {
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
}

.details-sidebar {
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.breadcrumb {
  margin-bottom: 20px;
  font-size: 14px;
}

.issue-title {
  margin-bottom: 20px;
}

.issue-description-card,
.issue-details-card,
.issue-comments-card {
  margin-bottom: 20px;
}

.issue-details-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f4f5f7;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  width: 200px;
  padding: 1rem;
  background-color: #f4f5f7;
  overflow-y: auto;
}

.main-content {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.right-sidebar {
  width: 300px;
  padding: 1rem;
  background-color: #f4f5f7;
  overflow-y: auto;
}

.action-buttons {
  margin-bottom: 1rem;
}

.action-buttons button {
  margin-right: 0.5rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.sidebar {
  position: relative;
  height: 100%;
  transition: all 0.3s;
}

.sidebar.closed {
  width: 50px;
}

.sidebar .toggle-btn {
  position: absolute;
  right: -15px;
  top: 10px;
  z-index: 1000;
}

.sidebar.closed .toggle-btn {
  right: -30px;
}

.right-sidebar {
  font-size: 0.9rem;
}

.right-sidebar .card-header {
  background-color: #f4f5f7;
  font-weight: 600;
}

.reporter-avatar {
  width: 24px;
  height: 24px;
  background-color: #ff9800;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.priority-indicator {
  width: 16px;
  height: 3px;
  background-color: #ffab00;
}

.right-sidebar .btn-light {
  text-align: left;
  background-color: #f4f5f7;
  border-color: #dfe1e6;
}

.right-sidebar .btn-light:hover {
  background-color: #ebecf0;
}

